:root {
  --bg: #FBFBFB;
  --cl: #135D66;
  --ff: #fff;
  --bl: #000;
  --cc: #ccc;
  --gg: #135D66;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  background: var(--bg);
}

::-webkit-scrollbar-thumb {
  background: var(--cl);
  border-radius: 20px;
}



input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

body {
  overflow-x: hidden;
  max-width: 1280px;
  margin: auto;
  background: var(--bg);
}