@font-face {
    font-family: 'AmmanV3Sans-Regular';
    src: url('./AmmanV3Sans-Regular.otf');

}


A.link1:link {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 13px;
    color: #566676;
    text-decoration: none;
    font-weight: normal;
}

A.link1:active {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 13px;
    color: #566676;
    text-decoration: none;
    font-weight: normal;
}

A.link1:visited {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 13px;
    color: #566676;
    text-decoration: none;
    font-weight: normal;
}

A.link1:hover {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 13px;
    color: #777779;
    text-decoration: underline;
    font-weight: normal;
}



A.linkpay:link {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 14px;
    color: #566676;
    text-decoration: none;
    font-weight: normal;
}

A.linkpay:active {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 14px;
    color: #566676;
    text-decoration: none;
    font-weight: normal;
}

A.linkpay:visited {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 14px;
    color: #566676;
    text-decoration: none;
    font-weight: normal;
}

A.linkpay:hover {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 14px;
    color: #cc0000;
    text-decoration: underline;
    font-weight: normal;
}



A.link2:link {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 10px;
    color: #777779;
    text-decoration: underline;
    font-weight: normal;
}

A.link2:active {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 10px;
    color: #777779;
    text-decoration: underline;
    font-weight: normal;
}

A.link2:visited {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 10px;
    color: #777779;
    text-decoration: underline;
    font-weight: normal;
}

A.link2:hover {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 10px;
    color: #185986;
    text-decoration: none;
    font-weight: normal;
}



A.link3:link {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 10px;
    color: #a1a1a1;
    text-decoration: underline;
    font-weight: normal;
}

A.link3:active {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 10px;
    color: #a1a1a1;
    text-decoration: underline;
    font-weight: normal;
}

A.link3:visited {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 10px;
    color: #a1a1a1;
    text-decoration: underline;
    font-weight: normal;
}

A.link3:hover {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 10px;
    color: #185986;
    text-decoration: none;
    font-weight: normal;
}


A.link4:link {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 12px;
    color: #ffffff;
    text-decoration: none;
    font-weight: normal;
}

A.link4:active {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 12px;
    color: #ffffff;
    text-decoration: none;
    font-weight: normal;
}

A.link4:visited {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 12px;
    color: #ffffff;
    text-decoration: none;
    font-weight: normal;
}

A.link4:hover {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 12px;
    color: #185986;
    text-decoration: underline;
    font-weight: normal;
}

A.link5:link {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 14px;
    color: #ffffff;
    text-decoration: none;
    font-weight: normal;
}

A.link5:active {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 14px;
    color: #ffffff;
    text-decoration: none;
    font-weight: normal;
}

A.link5:visited {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 14px;
    color: #ffffff;
    text-decoration: none;
    font-weight: normal;
}

A.link5:hover {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 14px;
    color: #185986;
    text-decoration: underline;
    font-weight: normal;
}


A.link6:link {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 14px;
    color: #566676;
    text-decoration: none;
    font-weight: normal;
}

A.link6:active {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 14px;
    color: #566676;
    text-decoration: none;
    font-weight: normal;
}

A.link6:visited {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 14px;
    color: #566676;
    text-decoration: none;
    font-weight: normal;
}

A.link6:hover {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 14px;
    color: #777779;
    text-decoration: underline;
    font-weight: normal;
}


A.link7:link {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 13px;
    color: #566676;
    text-decoration: none;
    font-weight: normal;
}

A.link7:active {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 13px;
    color: #566676;
    text-decoration: none;
    font-weight: normal;
}

A.link7:visited {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 13px;
    color: #566676;
    text-decoration: none;
    font-weight: normal;
}

A.link7:hover {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 13px;
    color: #777779;
    text-decoration: underline;
    font-weight: normal;
}


.input1 {
    border-right: #a0c1fa 1px solid;
    border-top: #a0c1fa 1px solid;
    border-left: #a0c1fa 1px solid;
    border-bottom: #a0c1fa 1px solid;
    font-size: 13px;
    width: 160px;
    background-color: #ffffff;
    height: 20px;
    direction: rtl;
    font-family: AmmanV3Sans-Regular, tahoma;
    color: #566676;
}

.cinput {
    border-right: #a0c1fa 1px solid;
    border-top: #a0c1fa 1px solid;
    border-left: #a0c1fa 1px solid;
    border-bottom: #a0c1fa 1px solid;
    font-size: 36px;
    width: 160px;
    background-color: #ffffff;
    height: 60px;
    text-align: center;
    text-transform: uppercase;
    font-family: AmmanV3Sans-Regular, tahoma;
    color: #566676;
}

.minput {
    border-right: #a0c1fa 1px solid;
    border-top: #a0c1fa 1px solid;
    border-left: #a0c1fa 1px solid;
    border-bottom: #a0c1fa 1px solid;
    font-size: 30px;
    width: 180px;
    background-color: #ffffff;
    height: 60px;
    text-align: center;
    text-transform: uppercase;
    font-family: AmmanV3Sans-Regular, tahoma;
    color: #566676;
}

.qrinput {
    border-right: #a0c1fa 1px solid;
    border-top: #a0c1fa 1px solid;
    border-left: #a0c1fa 1px solid;
    border-bottom: #a0c1fa 1px solid;
    font-size: 32px;
    width: 500px;
    background-color: #ffffff;
    height: 60px;
    text-align: center;
    text-transform: uppercase;
    font-family: AmmanV3Sans-Regular, tahoma;
    color: #566676;
}


.textarea {
    resize: none;
    width: 560px;
    height: 160px;
    font-size: 16px;
    border-right: #E1E6EA 1px solid;
    border-top: #E1E6EA 1px solid;
    border-left: #E1E6EA 1px solid;
    border-bottom: #E1E6EA 1px solid;
}

.sinput1 {
    border-right: #E1E6EA 1px solid;
    border-top: #E1E6EA 1px solid;
    font-size: 13px;
    border-left: #E1E6EA 1px solid;
    width: 60px;
    height: 20px;
    border-bottom: #E1E6EA 1px solid;
    font-family: AmmanV3Sans-Regular, tahoma;
    background-color: #ffffff;
    color: #4b3900;
}

.ssinput1 {
    border-right: #E1E6EA 1px solid;
    border-top: #E1E6EA 1px solid;
    font-size: 13px;
    border-left: #E1E6EA 1px solid;
    width: 30px;
    height: 20px;
    border-bottom: #E1E6EA 1px solid;
    font-family: AmmanV3Sans-Regular, tahoma;
    background-color: #ffffff;
    color: #4b3900;
}

.input11 {
    border-right: #E1E6EA 1px solid;
    border-top: #E1E6EA 1px solid;
    font-size: 13px;
    border-left: #E1E6EA 1px solid;
    width: 260px;
    height: 20px;
    border-bottom: #E1E6EA 1px solid;
    font-family: AmmanV3Sans-Regular, tahoma;
    background-color: #ffffff;
    color: #4e4e50;
}

.input111 {
    border-right: #E1E6EA 1px solid;
    border-top: #E1E6EA 1px solid;
    font-size: 13px;
    border-left: #E1E6EA 1px solid;
    width: 460px;
    height: 20px;
    border-bottom: #E1E6EA 1px solid;
    font-family: AmmanV3Sans-Regular, tahoma;
    background-color: #ffffff;
    color: #4e4e50;
}

.sinput11 {
    border-right: #E1E6EA 1px solid;
    border-top: #E1E6EA 1px solid;
    font-size: 13px;
    border-left: #E1E6EA 1px solid;
    width: 150px;
    height: 20px;
    border-bottom: #E1E6EA 1px solid;
    font-family: AmmanV3Sans-Regular, tahoma;
    background-color: #ffffff;
    color: #4e4e50;
}

.brownbtn {
    border-right: #E1E6EA 1px solid;
    border-top: #E1E6EA 1px solid;
    font-size: 13px;
    height: 30px;
    border-left: #E1E6EA 1px solid;
    width: 160px;
    border-bottom: #E1E6EA 1px solid;
    font-family: AmmanV3Sans-Regular, tahoma;
    background-color: #ffffff;
    color: #000000;
    border-color: #757575;
    border-radius: 5px;
    margin-bottom: 0;
    border: 1px solid;
    font-size: 13px !important;
    font-weight: 500;
}


.BIGbrownbtn {
    border-right: #E1E6EA 1px solid;
    border-top: #E1E6EA 1px solid;
    font-size: 13px;
    height: 30px;
    border-left: #E1E6EA 1px solid;
    width: 260px;
    border-bottom: #E1E6EA 1px solid;
    font-family: AmmanV3Sans-Regular, tahoma;
    background-color: #ffffff;
    color: #000000;
    border-color: #757575;
    border-radius: 5px;
    margin-bottom: 0;
    border: 1px solid;
    font-size: 13px !important;
    font-weight: 500;
}



.brownbtn:hover {
    border-right: #E1E6EA 1px solid;
    border-top: #E1E6EA 1px solid;
    font-size: 13px;
    height: 30px;
    border-left: #E1E6EA 1px solid;
    width: 160px;
    border-bottom: #E1E6EA 1px solid;
    font-family: AmmanV3Sans-Regular, tahoma;
    background-color: #e2eaed;
    color: #000000;
    border-color: #757575;
    border-radius: 5px;
    margin-bottom: 0;
    border: 1px solid;
    font-size: 13px !important;
    font-weight: 500;
}




.lbrownbtn {
    border-right: #E1E6EA 1px solid;
    border-top: #E1E6EA 1px solid;
    font-size: 13px;
    height: 30px;
    border-left: #E1E6EA 1px solid;
    width: 180px;
    border-bottom: #E1E6EA 1px solid;
    font-family: AmmanV3Sans-Regular, tahoma;
    background-color: #ffffff;
    color: #000000;
    border-color: #757575;
    border-radius: 5px;
    margin-bottom: 0;
    border: 1px solid;
    font-size: 13px !important;
    font-weight: 500;
}




.paybtn {
    color: #4e4e50;
    background: #b0cce2;
    width: 350px;
    height: 35px;
    font-weight: bold;
    font-size: 13px;
    font-family: AmmanV3Sans-Regular, tahoma;
    border: 1px solid #4e4e50;
}

.paybtn:hover {
    color: #4e4e50;
    font-weight: bold;
    background: #b0cce2;
    width: 350px;
    font-family: AmmanV3Sans-Regular, tahoma;
    height: 35px;
    font-size: 13px;
    border: 2px solid #ffffff;
}




.paybtns {
    color: #4e4e50;
    background: #b0cce2;
    width: 100px;
    height: 35px;
    font-weight: bold;
    font-size: 13px;
    font-family: AmmanV3Sans-Regular, tahoma;
    border: 1px solid #4e4e50;
}

.objectionbtn {
    color: #4e4e50;
    background: #b0cce2;
    width: 200px;
    height: 35px;
    font-weight: normal;
    font-size: 14px;
    font-family: AmmanV3Sans-Regular, tahoma;
    border: 1px solid #4e4e50;
}

.paybtns:hover {
    color: #4e4e50;
    font-weight: bold;
    background: #b0cce2;
    width: 100px;
    font-family: AmmanV3Sans-Regular, tahoma;
    height: 35px;
    font-size: 13px;
    border: 2px solid #ffffff;
}


.sbrownbtn {
    border-right: #E1E6EA 1px solid;
    border-top: #E1E6EA 1px solid;
    font-size: 13px;
    border-left: #E1E6EA 1px solid;
    width: 80px;
    height: 20px;
    border-bottom: #E1E6EA 1px solid;
    font-family: AmmanV3Sans-Regular, tahoma;
    background-color: #ffffff;
    color: #566676;
}


.winput1 {
    border-right: #E1E6EA 1px solid;
    border-top: #E1E6EA 1px solid;
    font-size: 13px;
    border-left: #E1E6EA 1px solid;
    width: 120px;
    height: 20px;
    border-bottom: #E1E6EA 1px solid;
    font-family: AmmanV3Sans-Regular, tahoma;
    background-color: #ffffff;
    color: #566676;
}

.swinput1 {
    border-right: #E1E6EA 1px solid;
    border-top: #E1E6EA 1px solid;
    font-size: 13px;
    border-left: #E1E6EA 1px solid;
    width: 40px;
    height: 20px;
    border-bottom: #E1E6EA 1px solid;
    font-family: AmmanV3Sans-Regular, tahoma;
    background-color: #ffffff;
    color: #566676;
}

.mwinput1 {
    border-right: #E1E6EA 1px solid;
    border-top: #E1E6EA 1px solid;
    font-size: 13px;
    border-left: #E1E6EA 1px solid;
    width: 80px;
    height: 20px;
    border-bottom: #E1E6EA 1px solid;
    font-family: AmmanV3Sans-Regular, tahoma;
    background-color: #ffffff;
    color: #566676;
}

.sblacktxt {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 13px;
    color: #566676;
    text-decoration: none;
    font-weight: normal;
}

.fsitefont {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 10px;
    color: #566676;
    text-decoration: none;
    font-weight: normal;
}

.gsitefont {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 11px;
    color: #566676;
    text-decoration: none;
    font-weight: bold;
}

.datesitefont {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 11px;
    color: #566676;
    text-decoration: none;
    font-weight: normal;
}

.ssitefont {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 9px;
    color: #566676;
    text-decoration: none;
    font-weight: normal;
}

.sitefont {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 13px;
    color: #566676;
    text-decoration: none;
    font-weight: normal;
}

.tsitefont {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 11px;
    color: #566676;
    text-decoration: none;
    font-weight: bold;
}

.gtsitefont {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 14px;
    color: #4e4e50;
    text-decoration: none;
    font-weight: bold;
}

.nsitefont {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 11px;
    color: #828284;
    text-decoration: none;
    font-weight: normal;
}

.swhitetxt {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 13px;
    color: #4e4e50;
    text-decoration: none;
    font-weight: bold;
}

.browntxt {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 14px;
    color: #4e4e50;
    text-decoration: none;
    font-weight: bold;
}

.mbrowntxt {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 20px;
    color: #4e4e50;
    text-decoration: none;
    font-weight: bold;
}

.bsitefont {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 14px;
    color: #4e4e50;
    text-decoration: none;
    font-weight: bold;
}

.sbrowntxt {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 15px;
    color: #828284;
    text-decoration: none;
    font-weight: normal;
}

.sbrowntxt1 {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 14px;
    color: #828284;
    text-decoration: none;
    font-weight: normal;
}

.sbrowntxtB {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 13px;
    color: #828284;
    text-decoration: none;
    font-weight: normal;
}

.vtxtbordM1 {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 11px;
    font-weight: bold;
    float: right;
    color: #4e4e50;
    border-bottom: 1px solid #4B3900;
    border-right: 1px solid #4B3900;
    border-left: 1px solid #e7e7e7;
    border-top: 1px solid #e7e7e7;
    width: 420px;
    height: 180px;
}

.errtxt {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 14px;
    color: #cc0000;
    text-decoration: none;
    font-weight: bold;
}

.oktxt {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 14px;
    color: Green;
    text-decoration: none;
    font-weight: bold;
}


.button {
    -webkit-appearance: button;
    -moz-appearance: button;
    text-decoration: none;
    color: initial;
}

.bigbrowntxt {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 16px;
    color: #4e4e50;
    text-decoration: none;
    font-weight: normal;
}

.bbigbrowntxt {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 22px;
    color: #4e4e50;
    text-decoration: none;
    font-weight: bold;
    text-align: center;
}

.errtxt1 {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 13px;
    color: #cc0000;
    text-decoration: none;
    font-weight: bold;
}



.GridPager a,
.GridPager span {
    display: block;
    height: 15px;
    width: 15px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
}

.GridPager a {
    background-color: #f5f5f5;
    color: #969696;
    border: 1px solid #969696;
}

.GridPager span {
    background-color: #A1DCF2;
    color: #000;
    border: 1px solid #3AC0F2;
}

#scrolly {
    width: 1000px;
    height: 190px;
    overflow: auto;
    overflow-y: hidden;
    margin: 0 auto;
    white-space: nowrap;
}

.blinki {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 18px;
    color: #faf457;
    text-decoration: none;
    font-weight: normal;
}

.blinkw {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 18px;
    color: #ffffff;
    text-decoration: none;
    font-weight: normal;
}

.tablecolor {
    width: 100%;
    padding: 0px;
    margin: 0 auto;
    background-color: #dcdddf;

}

.table {
    width: 100%;
    padding: 0px;
    margin: 0 auto;
}

.tdheight {
    height: 30px;
    width: 3%;
}

td,
th {
    padding: revert-layer;
}

.wsitefont {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 14px;
    color: #ffffff;
    text-decoration: none;
    font-weight: normal;
}

.ysitefont {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 16px;
    color: #fefb0a;
    text-decoration: none;
    font-weight: normal;
}

#overlay {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    filter: alpha(opacity=70);
    -moz-opacity: 0.7;
    -khtml-opacity: 0.7;
    opacity: 0.7;
    z-index: 100;
    display: none;
    font-family: AmmanV3Sans-Regular, tahoma;
}

.cnt223 a {
    text-decoration: none;
    font-family: AmmanV3Sans-Regular, tahoma;
}

.popup {
    width: 100%;
    margin: 0 auto;
    display: none;
    position: fixed;
    z-index: 101;
    font-family: AmmanV3Sans-Regular, tahoma;
}

.cnt223 {
    font-family: AmmanV3Sans-Regular, tahoma;
    min-width: 600px;
    width: 600px;
    min-height: 150px;
    margin: 100px auto;
    background: #f3f3f3;
    position: relative;
    z-index: 103;
    padding: 15px 35px;
    border-radius: 5px;
    box-shadow: 0 2px 5px #000;
}

.cnt223 p {
    clear: both;
    color: #555555;
    /* text-align: justify; */
    font-size: 20px;
    font-family: AmmanV3Sans-Regular, tahoma;
}

.cnt223 p a {
    color: #d91900;
    font-weight: bold;
    font-family: AmmanV3Sans-Regular, tahoma;
}

.cnt223 .x {
    float: right;
    height: 35px;
    left: 22px;
    position: relative;
    top: -25px;
    width: 34px;
    font-family: AmmanV3Sans-Regular, tahoma;
}

.cnt223 .x:hover {
    cursor: pointer;
    font-family: AmmanV3Sans-Regular, tahoma;
}

A.linkbig:link {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 18px;
    color: #566676;
    text-decoration: none;
    font-weight: normal;
}

A.linkbig:active {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 18px;
    color: #566676;
    text-decoration: none;
    font-weight: normal;
}

A.linkbig:visited {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 18px;
    color: #566676;
    text-decoration: none;
    font-weight: normal;
}

A.linkbig:hover {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 18px;
    color: #777779;
    text-decoration: underline;
    font-weight: normal;
}

.bigtitle {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 18px;
    color: #566676;

    font-weight: normal;
}

A.linkbold:link {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 13px;
    color: #566676;
    text-decoration: none;
    font-weight: bold;
}

A.linkbold:active {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 13px;
    color: #566676;
    text-decoration: none;
    font-weight: bold;
}

A.linkbold:visited {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 13px;
    color: #566676;
    text-decoration: none;
    font-weight: bold;
}

A.linkbold:hover {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 13px;
    color: #777779;
    text-decoration: underline;
    font-weight: bold;
}

.whitetxt {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 13px;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

.bottomRightShadow {
    width: 200px;
    height: 250px;
    border: solid 1px #555;
    background: #fff;
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.6);
    -o-box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.6);
}

.fullbottomRightShadow {
    background: #fff;
    border: solid 1px #555;
    height: 60px;
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.6);
    -o-box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.6);
}


.greenbuildinputForm {
    -moz-border-radius: 5px;
    /* Firefox */
    -webkit-border-radius: 5px;
    /* Safari, Chrome */
    -khtml-border-radius: 5px;
    /* KHTML */
    border-radius: 5px;
    /* CSS3 */

    BORDER-RIGHT: #1F273A 1px solid;
    BORDER-TOP: #1F273A 1px solid;
    FONT-SIZE: 15px;
    BORDER-LEFT: #1F273A 1px solid;
    text-align: center;
    height: 25px;
    BORDER-BOTTOM: #1F273A 1px solid;
    FONT-FAMILY: tahoma;
    BACKGROUND-COLOR: #aafaaf;
    color: #1F273A
}

.redbuildinputForm {
    -moz-border-radius: 5px;
    /* Firefox */
    -webkit-border-radius: 5px;
    /* Safari, Chrome */
    -khtml-border-radius: 5px;
    /* KHTML */
    border-radius: 5px;
    /* CSS3 */

    BORDER-RIGHT: #1F273A 1px solid;
    BORDER-TOP: #1F273A 1px solid;
    FONT-SIZE: 15px;
    BORDER-LEFT: #1F273A 1px solid;
    margin: 20px;
    height: 25px;
    BORDER-BOTTOM: #1F273A 1px solid;
    FONT-FAMILY: tahoma;
    BACKGROUND-COLOR: #cc0000;
    color: #FFFFFF;
    text-align: center;

}

.blink {
    animation: blinker 1s step-start infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    border-radius: 15px;

    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 30%;
}

.bigblack {
    FONT-WEIGHT: bold;
    padding-top: 10px;
    padding-right: 10px;
    COLOR: black;
}


A.linkwhite:link {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 15px;
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
}

A.linkwhite:active {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 15px;
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
}

A.linkwhite:visited {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 15px;
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
}

A.linkwhite:hover {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 15px;
    color: #ffffff;
    text-decoration: underline;
    font-weight: bold;
}

.upper-case {
    text-transform: uppercase
}





A.linkt:link {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 45px;
    color: #870337;
    text-decoration: none;
    font-weight: bold;
}

A.linkt:active {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 45px;
    color: #870337;
    text-decoration: none;
    font-weight: bold;
}

A.linkt:visited {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 45px;
    color: #870337;
    text-decoration: none;
    font-weight: bold;
}

A.linkt:hover {
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 45px;
    color: #590e2c;
    text-decoration: underline;
    font-weight: bold;
}


.button12 {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: solid 1px #20538D;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
    background: #4479BA;
    color: #FFF;
    font-family: AmmanV3Sans-Regular, tahoma;
    font-size: 45px;
    padding: 8px 12px;
    text-decoration: none;
}


.ebuildinputFormTel {
    border: 1px solid #1F273A;
    -moz-border-radius: 5px;
    /* Firefox */
    -webkit-border-radius: 5px;
    /* Safari, Chrome */
    -khtml-border-radius: 5px;
    /* KHTML */
    FONT-SIZE: 15px;
    WIDTH: 200px;
    height: 25px;
    padding-left: 5px;
    text-align: left;
    FONT-FAMILY: tahoma;
    BACKGROUND-COLOR: #ffffff;
    color: #1F273A
}

.ebuildinputFormTelExt {
    border: 1px solid #1F273A;
    -moz-border-radius: 5px;
    /* Firefox */
    -webkit-border-radius: 5px;
    /* Safari, Chrome */
    -khtml-border-radius: 5px;
    /* KHTML */
    FONT-SIZE: 15px;
    WIDTH: 60px;
    height: 25px;
    padding-left: 5px;
    text-align: left;
    FONT-FAMILY: tahoma;
    BACKGROUND-COLOR: #ffffff;
    color: #1F273A
}

.btable-mcurved {
    border-collapse: separate;
    border: solid #1F273A 2px;
    border-radius: 5px;
}

.redtable-mcurved {
    border-collapse: separate;
    border: solid #d40203 2px;
    background: #a30808;
    border-radius: 5px;
    font-family: AmmanV3Sans-Regular, tahoma;
}

.greentable-mcurved {
    border-collapse: separate;
    border: solid #75ca57 2px;
    background: green;
    border-radius: 5px;
    font-family: AmmanV3Sans-Regular, tahoma;
}

.errorfont {
    font-style: normal;
    font-size: 18px;
    font-weight: bold;
    color: Red;
    font-family: Arial, Times New Roman;
}

.acceptfont {
    font-style: normal;
    font-size: 18px;
    font-weight: bold;
    color: Black;
    font-family: Arial, Times New Roman;
}

.redfont {
    font-style: normal;
    font-size: 18px;
    font-weight: bold;
    color: Red;
    font-family: Arial, Times New Roman;
}

.greenfont {
    font-style: normal;
    font-size: 18px;
    font-weight: bold;
    color: #37a706;
    font-family: Arial, Times New Roman;
}

.btnconfirminputForm {
    -moz-border-radius: 5px;
    /* Firefox */
    -webkit-border-radius: 5px;
    /* Safari, Chrome */
    -khtml-border-radius: 5px;
    /* KHTML */
    border-radius: 5px;
    /* CSS3 */
    border-right: #1F273A 1px solid;
    border-top: #1F273A 1px solid;
    font-size: 15px;
    border-left: #1F273A 1px solid;
    width: 150px;
    height: 25px;
    border-bottom: #1F273A 1px solid;
    font-family: AmmanV3Sans-Regular, tahoma;
    background-color: #e7e7e7;
    color: #1F273A;
}

.btnconfirminputForm:hover {
    opacity: 0.7;
}

.qrcode {
    position: relative;
    width: 780px;
}

.qrcode_react {
    position: absolute;
    bottom: 100px;
    left: 105px;
}

.fixed {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100vh;
    background: #fff;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fixed_img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 9999;
}


.loader,
.loader:before,
.loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: bblFadInOut 1.8s infinite ease-in-out;
}

.loader {
    color: var(--cl);
    font-size: 7px;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
}

.loader:before,
.loader:after {
    content: '';
    position: absolute;
    top: 0;
}

.loader:before {
    left: -3.5em;
    animation-delay: -0.32s;
}

.loader:after {
    left: 3.5em;
}

@keyframes bblFadInOut {

    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em
    }

    40% {
        box-shadow: 0 2.5em 0 0
    }
}